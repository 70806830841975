import React, { useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';
import { IconNames } from '../../Icons/Icon';
import { HeaderVariant } from '../Header';
import { useState } from 'react';
import { LOGIN, PROFILE, SETTINGS } from '../../../constants/routes';
import NavigationAvatar from './NavigationAvatar';
import Popover, { positionRight } from '@reach/popover';
import { useRect } from '@reach/rect';
import { useRouter } from 'next/router';
import useLogoutUser from 'hooks/useLogoutUser';
import { useQueryClient } from 'react-query';

const STYLES = {
  light: {
    '--color': 'var(--color-gray-700)',
  },
  dark: {
    '--color': 'var(--color-white)',
  },
};

interface Props {
  variant: HeaderVariant;
  value: string;
  avatar: string;
  initials?: string;
}

const ProfileDropdown = ({ variant, value, avatar, initials }: Props) => {
  const styles = STYLES[variant];
  const ref = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);

  const router = useRouter();
  const queryClient = useQueryClient();
  const logout = useLogoutUser();

  const rect = useRect(ref, { observe: true });

  useLayoutEffect(() => {
    if (rect) {
      setPosition(rect.width);
    }
  }, [rect]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        setOpenPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const onLogout = () => {
    logout.mutate(1, {
      onSuccess: () => {
        router.push(LOGIN);
      },
    });
  };

  return (
    <>
      <NavLink
        style={styles}
        ref={ref}
        onClick={() => setOpenPopover(!openPopover)}
      >
        <Span>{value}</Span>
        <NavigationAvatar
          variant={HeaderVariant.light}
          avatar={avatar}
          initials={initials}
        />
      </NavLink>

      {openPopover ? (
        <Popover targetRef={ref} position={positionRight}>
          <PopoverContent
            ref={popoverRef}
            style={{ '--position': position + 'px' }}
          >
            <PopoverItem>
              <NavigationItem
                href={PROFILE}
                variant={HeaderVariant.dark}
                value="Profile"
                iconLeft
                id={IconNames.user}
              />
            </PopoverItem>

            <PopoverItem>
              <NavigationItem
                href={SETTINGS}
                variant={HeaderVariant.dark}
                value="Settings"
                iconLeft
                id={IconNames.gear}
              />
            </PopoverItem>

            <PopoverItem>
              <NavigationItem
                variant={HeaderVariant.dark}
                value="Log Out"
                iconLeft
                id={IconNames.logOut}
                onClick={onLogout}
              />
            </PopoverItem>
          </PopoverContent>
        </Popover>
      ) : null}
    </>
  );
};

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  filter: drop-shadow(var(--shadow-thumbnail-default));

  background: var(--color-gray-900);
  border-radius: 4px;
  margin-top: 24px;
  padding: 24px;

  &::after {
    content: '';
    /* z-index: -1; */
    width: 20px;
    height: 12px;
    position: absolute;
    top: 13px;
    right: calc(var(--position) / 2 - 10px);
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    background-color: var(--color-gray-900);
  }
`;

const NavLink = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  font-size: var(--font-size-body-01-bold);
  font-weight: var(--font-weight-semibold);
  font-family: 'HKGrotesk';
  color: var(--color);
  cursor: pointer;
`;

const Span = styled.span`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
`;

const PopoverItem = styled.div`
  margin: 0 -24px;
  padding: 0 24px;

  &:hover {
    filter: brightness(0.8);
  }
`;

export default ProfileDropdown;
