import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { UrlObject } from 'url';
import Icon, { IconNames } from '../../Icons';
import NavigationAvatar from './NavigationAvatar';
import { HeaderVariant } from '../Header';

declare type Url = string | UrlObject;

const VARIANTS = {
  light: {
    '--color': 'var(--color-gray-700)',
  },
  dark: {
    '--color': 'var(--color-white)',
  },
};

const ICONS = {
  light: 'var(--color-gray-700)',
  dark: 'var(--color-white)',
};

const FILLED = {
  light: {
    '--padding': 12 + 'px',
    '--border-radius': 12 + 'px',
    '--filled-color': 'var(--color-gray-100)',
  },
  dark: {
    '--padding': 12 + 'px',
    '--border-radius': 12 + 'px',
    '--filled-color': 'var(--color-primary-300)',
  },
};

interface BaseProps {
  variant: HeaderVariant;
  value: string;
}

interface DefaultProps {
  iconLeft?: never;
  iconRight?: never;
  id?: never;
  filled?: boolean;
  avatar?: never;
  avatarLeft?: never;
  initials?: never;
}

interface IconLeftProps {
  iconLeft: true;
  id: IconNames;
  iconRight?: never;
  filled?: never;
  avatar?: never;
  avatarLeft?: never;
  initials?: never;
}

interface IconRightProps {
  iconRight: true;
  id: IconNames;
  iconLeft?: never;
  filled?: never;
  avatar?: never;
  avatarLeft?: never;
  initials?: never;
}

interface WithAvatarRightProps {
  avatarLeft?: boolean; // if no avatarLeft provided, defaults to right hand side
  avatar: string;
  initials?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  id?: IconNames;
  filled?: never;
}

type BaseLinkProps = {
  href: Url;
  asExternal?: boolean;
  onClick?: never;
} & BaseProps &
  (DefaultProps | IconLeftProps | IconRightProps | WithAvatarRightProps);

type BaseButtonProps = {
  href?: never;
  asExternal?: never;
  onClick: () => void;
} & BaseProps &
  (DefaultProps | IconLeftProps | IconRightProps | WithAvatarRightProps);

type Props = BaseLinkProps | BaseButtonProps;

const NavigationItem = ({
  variant,
  href,
  asExternal,
  onClick,
  value,
  filled,
  iconLeft,
  iconRight,
  id,
  avatarLeft,
  avatar,
  initials,
}: Props) => {
  const styles = VARIANTS[variant];
  const filledStyles = filled ? FILLED[variant] : null;
  const iconColor = ICONS[variant];

  return href ? (
    asExternal ? (
      <NavLink
        href={href as string}
        target="_blank"
        style={{ ...styles, ...filledStyles }}
      >
        {/* LEFT AVATAR */}
        {!!avatarLeft && (!!avatar || !!initials) ? (
          <NavigationAvatar
            variant={variant}
            avatar={avatar}
            initials={initials}
          />
        ) : null}

        {/* LEFT ICON */}
        {iconLeft && !!id ? (
          <Icon id={id} color={iconColor} strokeWidth={2} />
        ) : null}

        {/* span used to get rid of extra pixels below text from font */}
        <Span>{value}</Span>

        {/* RIGHT ICON */}
        {iconRight && !!id ? (
          <Icon id={id} color={iconColor} strokeWidth={2} />
        ) : null}

        {/* RIGHT AVATAR */}
        {!!!avatarLeft && (!!avatar || !!initials) ? (
          <NavigationAvatar
            variant={variant}
            avatar={avatar}
            initials={initials}
          />
        ) : null}
      </NavLink>
    ) : (
      <Link href={href} passHref scroll={true}>
        <NavLink style={{ ...styles, ...filledStyles }}>
          {/* LEFT AVATAR */}
          {!!avatarLeft && (!!avatar || !!initials) ? (
            <NavigationAvatar
              variant={variant}
              avatar={avatar}
              initials={initials}
            />
          ) : null}

          {/* LEFT ICON */}
          {iconLeft && !!id ? (
            <Icon id={id} color={iconColor} strokeWidth={2} />
          ) : null}

          {/* span used to get rid of extra pixels below text from font */}
          <Span>{value}</Span>

          {/* RIGHT ICON */}
          {iconRight && !!id ? (
            <Icon id={id} color={iconColor} strokeWidth={2} />
          ) : null}

          {/* RIGHT AVATAR */}
          {!!!avatarLeft && (!!avatar || !!initials) ? (
            <NavigationAvatar
              variant={variant}
              avatar={avatar}
              initials={initials}
            />
          ) : null}
        </NavLink>
      </Link>
    )
  ) : (
    <NavLink
      style={{ ...styles, ...filledStyles }}
      as="button"
      onClick={onClick}
    >
      {iconLeft && !!id ? (
        <Icon id={id} color={iconColor} strokeWidth={2} />
      ) : null}
      {/* span used to get rid of extra pixels below text from font */}
      <Span>{value}</Span>
      {iconRight && !!id ? (
        <Icon id={id} color={iconColor} strokeWidth={2} />
      ) : null}
      {!!avatar || !!initials ? (
        <NavigationAvatar
          variant={variant}
          avatar={avatar}
          initials={initials}
        />
      ) : null}
    </NavLink>
  );
};

const NavLink = styled.a`
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: fit-content;

  font-size: var(--font-size-body-01-bold);
  font-weight: var(--font-weight-semibold);
  font-family: 'HKGrotesk';
  color: var(--color);
  background: var(--filled-color);
  border-radius: var(--border-radius);
  padding: var(--padding);
`;

const Span = styled.span`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

export default NavigationItem;
