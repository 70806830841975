import React from 'react';
import { IconNames } from '../../Icons';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';
import { LOGIN } from '../../../constants/routes';
import {
  FetchUserProfileResponse,
  SubscriptionResponse,
  Notification,
} from '@solin-fitness/types';
import { HeaderProps, HeaderVariant } from '../Header';
import ProfileDropdown from './NavigationProfileDropdown';
import { NotificationsIcon } from './NotificationIcons';
import { User } from 'types/auth';

const FOR_CREATORS_URL = process.env.NEXT_PUBLIC_FOR_CREATORS_URL || '';

const STYLES = {
  dark: {
    '--spacer-color': 'var(--color-white)',
  },
  light: {
    '--spacer-color': 'var(--color-gray-700)',
  },
};

type Props = {
  subscriptions?: SubscriptionResponse[];
  profile?: FetchUserProfileResponse;
  signedIn: boolean;
  notifications: Notification[];
} & HeaderProps;

/**
 * Signed OUT state of navigation menu
 * Includes our marketing page links and options to sign up or sign in
 */
const DefaultNavMenu = ({ variant }: { variant: HeaderVariant }) => (
  <NavMenu>
    <NavigationItem
      href={FOR_CREATORS_URL}
      asExternal
      variant={variant}
      value="For Creators"
    />
    <NavigationItem
      href={LOGIN}
      variant={variant}
      value="Sign In"
      iconRight
      id={IconNames.user}
    />
  </NavMenu>
);

/**
 * Signed IN state of navigation menu
 * Includes user's subscriptions and profile
 */
const SignedInNavMenu = ({
  variant,
  subscriptions,
  profile,
  notifications,
  user,
}: {
  variant: HeaderVariant;
  user: User | null;
  subscriptions?: SubscriptionResponse[];
  profile: FetchUserProfileResponse;
  notifications: Notification[];
}) => {
  const userInitials = `${profile.firstName?.[0]}${profile.lastName?.[0]}`;
  const spacerStyles = STYLES[variant];
  return (
    <NavMenu>
      {subscriptions?.map((subscription) => (
        <NavigationItem
          key={subscription.pageUrl}
          href={{
            pathname: '/[creatorPage]',
            query: {
              creatorPage: subscription.pageUrl,
            },
          }}
          variant={variant}
          value={subscription.pageName}
          avatar={subscription.creator.profile.profilePictureUrlPublicId}
        />
      ))}
      {!!subscriptions?.length ? <Spacer style={spacerStyles} /> : null}
      <ProfileDropdown
        variant={variant}
        value={profile.firstName}
        avatar={profile.profilePictureUrlPublicId}
        initials={userInitials}
      />
      <NotificationsItemWrap>
        <NotificationsIcon notifications={notifications} user={user} />
      </NotificationsItemWrap>
    </NavMenu>
  );
};

const NavigationMenu = ({
  variant,
  user,
  signedIn,
  subscriptions,
  profile,
  notifications,
}: Props) => {
  return signedIn && profile ? (
    <SignedInNavMenu
      variant={variant}
      user={user}
      subscriptions={subscriptions}
      profile={profile}
      notifications={notifications}
    />
  ) : (
    <DefaultNavMenu variant={variant} />
  );
};

const NavMenu = styled.nav`
  display: flex;
  gap: 42px;
`;

const NotificationsItemWrap = styled.div`
  margin-left: -21px;
  align-self: center;
`;

const Spacer = styled.div`
  width: 2px;
  margin: 0 -16px;
  background: var(--spacer-color);
`;

export default NavigationMenu;
