import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from './Components/Logo';
import NavigationMenu from './Components/NavigationMenu';
import UnstyledButton from '../UnstyledButton';
import Icon, { IconNames } from '../Icons/Icon';
import { useUserProfile } from '../../queries/user';
import useUserMetadata from '../../hooks/useUserMetadata';
import MobileMenu from './Components/MobileMenu';
import NavigationItem from './Components/NavigationItem';
import { UrlObject } from 'url';
import { Root } from '@radix-ui/react-visually-hidden';
import { NotificationsIcon } from './Components/NotificationIcons';
import { useNotifications } from 'components/Notifications/notificationsQuery';
import { User } from 'types/auth';

declare type Url = string | UrlObject;

const STYLES = {
  dark: {
    '--bg-color': 'var(--color-black-transparent)',
  },
  light: {
    '--bg-color': 'var(--color-white-transparent)',
  },
};

export enum HeaderVariant {
  dark = 'dark',
  light = 'light',
}

export interface HeaderProps {
  variant: HeaderVariant;
  user: User | null;
}

interface WithLogoProps {
  withoutLogo?: never;
  href?: never;
  linkValue?: never;
}

interface WithoutLogoProps {
  withoutLogo: true;
  href: Url;
  linkValue: string;
}

type Props = HeaderProps & (WithLogoProps | WithoutLogoProps);

const Header = ({ variant, user, withoutLogo, href, linkValue }: Props) => {
  const styles = STYLES[variant];

  const isSignedIn = !!user;

  const { data: profile } = useUserProfile(isSignedIn);
  const { data: metadata } = useUserMetadata(isSignedIn);
  const { data: notifications } = useNotifications(isSignedIn);

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  return (
    <HeaderWrapper style={styles}>
      <MainHeader>
        {withoutLogo && href ? (
          <NavigationItem
            href={href}
            variant={variant}
            value={linkValue!}
            iconLeft
            id={IconNames.chevronLeft}
          />
        ) : (
          <Logo variant={variant} />
          // <p>hello</p>
        )}
        <DesktopNav>
          <NavigationMenu
            variant={variant}
            signedIn={isSignedIn}
            user={user}
            subscriptions={metadata?.subscriptions}
            profile={profile}
            notifications={notifications || []}
          />
        </DesktopNav>
        <MobileNav>
          {isSignedIn ? (
            <NotificationsIcon
              notifications={notifications || []}
              user={user}
            />
          ) : null}
          <UnstyledButton onClick={() => setShowMobileMenu(true)}>
            <Root>Menu</Root>
            <Icon id={IconNames.menu} color="var(--color-white)" />
          </UnstyledButton>
        </MobileNav>
      </MainHeader>
      <MobileMenu
        isOpen={showMobileMenu}
        onDismiss={() => setShowMobileMenu(false)}
        signedIn={isSignedIn}
        subscriptions={metadata?.subscriptions}
        profile={profile}
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background: var(--bg-color);
`;

const MainHeader = styled.div`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px var(--spacing-64);
  min-height: 48px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding: 6px 36px;
  }
  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 6px 20px;
  }
`;

const DesktopNav = styled.nav`
  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: none;
  }
`;

const MobileNav = styled.nav`
  display: none;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export default Header;
